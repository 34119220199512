<template>
  <div class="all">
    <div class="mapAll">
      <div class="mapPart">
        <div class="brandLeft">
          <div class="introduce">
            <div class="maptitle" @click="goHome">返回首页</div>
            <div class="mapBrand" v-for="tab in tabsList" :key="tab.id" @click="jump(tab.id)">{{ tab.text }}</div>
          </div>
          <div class="contact">
            <div class="maptitle">联系我们</div>
            <div class="mapBrand">Fender1954@163.com</div>
            <div class="mapBrand">MusicWeaponsChina@gmail.com</div>
            <div class="mapBrand">Tele 13151773070</div>
            <div class="mapBrand">江苏省连云港市海州区陇海步行中街二层4-215号</div>
            <div class="mapBrand">No. 4-215, 2nd Floor, Longhai Buxingjie street, Haizhou District, Lianyungang
              City,Jiangsu
              Province</div>
          </div>
        </div>
        <div class="map">
          <div id="container" :style="{ width: mapWidth + 'px', height: mapHeight + 'px' }"></div>
        </div>
      </div>
    </div>
    <div class="bottomMsg">
      <div>Copyright© 2002-2024 Music Weapons版权所有</div>
    </div>
  </div>
</template>
<script>
import VueSeamlessScroll from 'vue-seamless-scroll/src'
import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from '@vue/reactivity'

export default {
  components: {
    VueSeamlessScroll
  },
  data() {
    return {
      tabsList: [
        { id: 1, text: '品牌分类' },
        // { id: 2, text: '功能' },
        { id: 3, text: '维修定制' },
        // { id: 4, text: '教学视频' },
        { id: 5, text: '案例展示' },
        { id: 6, text: '关于我们' }
      ],
      mapWidth: 420,
      mapHeight: 340,
    }
  },
  setup() {
    const map = shallowRef(null);
    return {
      map,
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  methods: {
    goHome() {
      this.$router.push({
        name: 'home',
        state: {
          scrollFlag: 'top'
        }
      })
    },
    initMap() {
      var windowWidth = window.innerWidth;
      var windowHeight = window.innerHeight;
      var bl = windowWidth / 1440;
      console.log(windowWidth, windowHeight)
      // this.mapWidth = Math.floor(39 * windowWidth / 144);
      // this.mapHeight = Math.floor(300 * windowHeight / 754);
      this.mapWidth = Math.floor(390 * bl);
      this.mapHeight = Math.floor(300 * bl);
      AMapLoader.load({
        key: "e6e8e3edbd6a251f08ea9adc1a3d0f13",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 10,           //初始化地图级别
          center: [119.186703, 34.606636], //初始化地图中心点位置
        });
        const marker = new AMap.Marker({
          position: [119.186703, 34.606636] //位置
        })
        this.map.add(marker); //添加到地图
      }).catch(e => {
        console.log(e);
      })
    },
    jump(id) {
      console.log(1111, id);
      if (id === 6) {
        this.$router.push({
          name: 'aboutus'
        })
      } else {
        // 维修视频or教学视频
        if (this.$router.currentRoute._value.name == 'home') {
          this.$emit('scroll', id)
        } else {
          this.$router.push({
            name: 'home',
            state: {
              scrollFlag: id
            }
          })
        }

      }
    }
  },
}
</script>
<style lang="scss" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 8px;
}

.bottomMsg {
  font-size: 15px;
  font-family: 'AlibabaPuHuiTi_3_55_Regular';
  color: rgba(255, 255, 255, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
}

.all {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/brandbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;
}

.mapAll {
  padding: 100px;
}

.mapPart {
  padding: 30px 0;
  color: #fff;
  display: flex;
  border-radius: 12px;
  border: 4px solid #FCECC0;
  position: relative;
  align-items: center;
  justify-content: center;

  .maptitle {
    width: 100%;
    font-size: 16px;
    font-family: 'AlibabaPuHuiTi_3_55_Regular';
    color: #FCECC0;
    padding: 14px 0;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    text-align: left;
  }

  .mapBrand {
    padding: 14px 0;
    font-size: 16px;
    font-family: 'AlibabaPuHuiTi_3_55_Regular';
    color: #FFFFFF;
  }

  .brandLeft {
    margin-right: 39px;
    display: flex;

    .introduce {
      width: 184px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 18px;
    }

    .contact {
      width: 343px;
      display: flex;
      margin-right: 18px;
      flex-direction: column;
      align-items: flex-start;

      .mapBrand {
        font-size: 15px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #FFFFFF;
        text-align: left;
      }
    }
  }

}
</style>