<template>
  <div class="all">
    <TopPart class="tabsAll">
    </TopPart>
    <div class="topPart" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
      <div class="topr">
        <!-- <div class="Productname">Search</div> -->
        <div class="title">reuslts for "{{ searchMsg }}"</div>
      </div>
    </div>
    <div class="productList">
      <div class="prdvo" @click="goPduDetail(item)" v-for="(item, index) in productList" :key="index">
        <img :src="item.image" class="logo" width="255" height="255" />
        <div class="itembottom">
          <div class="name">{{ item.productName }}</div>
          <div class="desc">
            <div class="price">¥{{ item.price }}</div>
            <div class="go">More</div>
            <el-icon class="cha">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout="prev, pager, next"
      :page-count="totalCount" prev-text="上一页" next-text="下一页" class="pagination"></el-pagination>
    <mapPart class="mapAll">
    </mapPart>
  </div>
</template>
<script>
import Api from '@/api/api'
import mapPart from '@/components/map'
import TopPart from '@/components/TopPart'
export default {
  components: {
    mapPart,
    TopPart
  },
  data() {
    return {
      showClist: false,
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      productList: [],
      bgImage: require('@/assets/aboutbanner.png'),
      searchMsg: ""
    }
  },
  mounted() {
    this.searchMsg = history.state.searchmsg
    this.getAllProductList(1)
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val
      this.getAllProductList(val)
    },
    getAllProductList(page) {
      Api.getAllProductList({
        page: page,
        rows: 99,
        orderName: "",
        asc: true,
        skuCode: "",
        productName: this.searchMsg
      }).then((res) => {
        if (res) {
          this.productList = res
          this.totalCount = res.totalPage
        }
      }).catch(() => { })
    },
    goPduDetail(item) {
      this.$router.push({
        name: 'product',
        state: {
          productCode: item.productCode
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.pagination {
  width: 100%;
}

::v-deep .el-pagination .el-pager li {
  background-color: transparent !important;
}

::v-deep .el-pagination button {
  background-color: transparent !important; //修改默认的背景色
}

::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.all {
  background: #000;
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title2 {
  height: 100px;
  line-height: 100px;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.topPart {
  width: 100%;
  height: 495px;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;


  .topr {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .desc {
    color: #fff;
    font-size: 22px;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    display: -webkit-box;
    -webkit-line-clamp: 3; // 超出多少行
    -webkit-box-orient: vertical;
  }

  .title {
    font-family: "AlibabaPuHuiTi-3-55-Regular";
    font-size: 50px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.tabs {
  height: 200px;
  display: flex;
  align-items: flex-start;

  .halo:before {
    height: 50px !important;
  }

  .left {
    width: 30%;
    height: 100%;
    margin-top: 12px;
    color: #fcecc0;
    font-weight: bold;
    font-size: 26px;
    line-height: 200px;
  }

  .right {
    color: #fff;
    flex: 1;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 20px 0 100px;

    .child {
      white-space: nowrap;
    }

    .transition-box {
      position: absolute;
      top: 50px;
      left: 0;
      display: flex;
    }

    .choosed {
      color: #fcecc0;
    }

    .tab {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: relative;
      font-size: 23px;
      margin-right: 30px;
    }

    .arrowdown {
      font-size: 14px;
    }

    .tab::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right;
      bottom: 0;
      height: 2px;
      background: #fcecc0;
      transition: transform .5s cubic-bezier(0.93, 0.07, 0.17, 1);
    }

    div:hover::before {
      transform: scaleX(1);
      transform-origin: left;
    }

    .child {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: relative;
      font-size: 23px;
    }

    .child::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right;
      bottom: 0;
      height: 2px;
      background: #fcecc0;
      transition: transform .5s cubic-bezier(0.93, 0.07, 0.17, 1);
    }

    div:hover::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  .tab {
    margin-right: 10px;
  }

  .child {
    margin-right: 8px;
  }
}

.productList {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 162px 50px 182px;

  .prdvo {
    min-width: 225px;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: #fff;
    overflow: hidden;
    border-radius: 0px 0px 6px 6px;
    margin-right: 19px;
    margin-bottom: 18px;

    .logo {
      min-width: 225px;
      min-height: 225px;
    }

    .itembottom {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 7px 12px;
      background: #ABABAB;
    }

    .name {
      word-wrap: break-word;
      width: 201px;
      font-size: 24px;
      text-align: left;
      height: 65px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box;
      -webkit-line-clamp: 3; // 超出多少行
      -webkit-box-orient: vertical;
      font-size: 15px;
      font-family: 'AlibabaPuHuiTi_3_55_Regular';
      color: #000000;
    }

    .desc {
      display: flex;
      align-items: center;
      flex: 1;

      .price {
        flex: 1;
        text-align: left;
        font-size: 22px;
        font-family: 'AlibabaPuHuiTi_3_85_Bold';
        color: #E93434;
        font-weight: bold;
      }

      .go {
        flex: 1;
        text-align: right;
        color: #fcecc0;
        font-size: 15px;
        font-family: 'AlibabaPuHuiTi_3_85_Bold';
      }

      .cha {
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }
}
</style>