<template>
  <div class="all">
    <TopPart class="tabsAll">
    </TopPart>
    <div class="main">
      <div class="bannerPart" :style="{ backgroundImage: computedBackgroundImage }">
        <div class="name">
          <div class="title">About us</div>
          <div class="title2">关于我们</div>
        </div>
      </div>
      <div class="content">
        <div class="big">Music Weapons</div>
        <div class="small">Music Weapons 位于中国江苏省连云港市，始创于2008年，专业从事吉他拾音器及其相关配件销售，专注于吉他电路改装服务，坚持“以真诚之心，行信义之事”。</div>
        <div class="small">对于每个爱乐之人，适合自己的，就是最好的。</div>
        <div class="small">对于我们，真正的高手懂得如何用减法，而不是只会做加法。</div>
        <div class="small">我们砥砺前行，至今不忘初心，你的所想所追，就是我们努力的方向。如此，我们才做到了一直被模仿，从未被超越。</div>
        <div class="small top">Established in 2008, located in Lianyungang City, Jiangsu Province, China.</div>
        <div class="small">We have been specializing in guitar pickups and related accessories sales.</div>
        <div class="small">Creating the most focusing circuit modification services. </div>
        <div class="small">In sincerity & In faithfulness.</div>
        <div class="small">For those who want to explore sound manipulation, the most suitable one is the best!</div>
        <div class="small">For us, the best builder are masters of subtraction.</div>
        <div class="small">Stay true and never forget the beginning. </div>
        <div class="small">What leads our efforts is your imagination and pursuit of sound.</div>
        <div class="small">In this way,</div>
        <div class="small">We have always been imitated and never surpassed.</div>
        <el-image class="aboutbanner2" :src="require('@/assets/about.png')" :fit="fit"></el-image>
      </div>
    </div>
    <mapPart class="mapAll">
    </mapPart>
  </div>
</template>
<script>
import mapPart from '@/components/map'
import TopPart from '@/components/TopPart'
import aboutBanner from '@/assets/aboutbanner.png';
export default {
  components: {
    mapPart,
    TopPart
  },
  data() {
    return {
// 假设你有一个变量来决定背景图
currentBanner: 'aboutbanner',
    }
  },
  computed: {
    computedBackgroundImage() {
      // 动态计算背景图片路径
      const image = this.currentBanner === 'aboutbanner' ? aboutBanner : '其他的图片路径';
      return `url(${image})`;
    },
  },
  mounted() {
    window.scrollTo({
      top: 0
    });
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.all {
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ::v-deep .el-pagination {
  justify-content: flex-end !important;
}

::v-deep .el-pagination .el-pager li {
  background-color: transparent !important;
}

::v-deep .el-pagination button {
  background-color: transparent !important; //修改默认的背景色
}

::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

::v-deep.el-pagination .el-pager .number {
  font-size: 20px !important;
  color: #fff;
}

::v-deep.el-pagination .btn-next {
  font-size: 20px !important;
  color: #fff;
  margin-left: 20px;
}

::v-deep.el-pagination .btn-prev {
  font-size: 20px !important;
  color: #fff;
  margin-right: 20px;
}

::v-deep.el-pagination .is-active {
  color: #E7931C !important;
}


  .main {
    .bannerPart {
      width: 100%;
      height: 495px;
      background-size: 100% 100%;
      position: relative;

      .aboutbanner{
      width: 100%;
      }

      .name {
        position: absolute;
        top: 101px;
        left: 186px;

        .title {
          font-family: 'AlibabaPuHuiTi-3-75-SemiBold';
          font-size: 36px;
          color: #FCECC0;
          text-align: left;
        }

        .title2 {
          font-size: 27px;
          font-family: 'AlibabaPuHuiTi_3_55_Regular';
          color: #FFFFFF;
          line-height: 38px;
          text-align: left;
          margin-top: 14px;
        }
      }
    }

    .content {
      padding: 74px 170px 96px 170px;
      height: 100%;
      background-image: url('@/assets/brandbg.jpg');
      background-size: 100% 100%;
      background-repeat: repeat-y;
      display: flex;
      flex-direction: column;
      position: relative;

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .big {
        font-size: 30px;
        font-family: 'AlibabaPuHuiTi_3_85_Bold';
        font-weight: bold;
        color: #FFFFFF;
        text-align: left;
        margin-bottom: 56px;
      }

      .top {
        margin-top: 40px;
      }

      .small {
        font-size: 21px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #FFFFFF;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        line-height: 50px;
      }

      .aboutbanner2 {
        width: 1080px;
        height: 552px;
        margin-top: 50px;
      }
    }

  }
}
</style>