<template>
  <div class="comtopPart">
    <img :src="require('@/assets/dianzhao.png')" class="logoPart" @click="goHome" />
    <div class="middle">
      <div class="tabs">
        <template v-for="(tab, index) in tabsList" :key="tab.id">
          <div class="tab" :class="{ 'tabactive': num == index, 'tabtextPadding': index === 0 }">
            <div @click="scrollTo(tab, index)" class="tabText">
              <div class="left">
                <div class="chinawen">{{ tab.text }}</div>
                <div class="enwen">{{ tab.en }}</div>
              </div>
              <div class="right" v-if="index == 0">
                <CaretBottom class="arrordown" />
              </div>
            </div>
            <transition name="fade">
              <div class="detail" v-if="tab.showFun" @mouseleave="tab.showFun = false, num = -1">
                <el-cascader-panel :options="realList" :props="{ expandTrigger: 'hover' }"
                  @change="handleChange"></el-cascader-panel>
              </div>
            </transition>
          </div>
        </template>
      </div>
      <!-- <div style="flex:1;" @click="invisible"></div> -->
      <div class="search">
        <el-input v-model="input" placeholder="Search" class="searchInput" />
        <el-icon class="searchIcon" @click="search">
          <Search />
        </el-icon>
      </div>
    </div>
  </div>
</template>
<script>

import Api from '@/api/api'
export default {
  components: {},
  data() {
    return {
      showFun: false,
      num: -1,//悬停区文字原始颜色
      tabsList: [
        { id: 1, text: '品牌分类', en: 'Brands' },
        { id: 3, text: '维修定制', en: 'Repair & Customization' },
        { id: 5, text: '案例展示', en: 'Workshop Galleries' },
        { id: 6, text: '关于我们', en: 'About Us' }
      ],
      realList: [],
      functionList: [
        {
          label: '吉他',
          value: '1',
          children: [
            {
              label: '箱琴',
              value: '1-1',
              children: []
            },
            {
              label: '电吉他',
              value: '1-2',
              children: []
            }
          ]
        },
        {
          label: '音响',
          value: '2',
          children: []
        },
        {
          label: '效果器',
          value: '3',
          children: []
        },
        {
          label: '拾音器',
          value: '4',
          children: [
            {
              label: 'Strat',
              value: '4-1',
              children: [
                {
                  label: '经典单',
                  value: '4-1-1',
                },
                {
                  label: '无噪音单',
                  value: '4-1-2',
                },
                {
                  label: '小双',
                  value: '4-1-3',
                }
              ]
            },
            {
              label: 'Tele',
              value: '4-2',
              children: [
                {
                  label: '经典单',
                  value: '4-2-1',
                },
                {
                  label: '无噪音单',
                  value: '4-2-2',
                },
                {
                  label: '小双',
                  value: '4-2-3',
                }
              ]
            },
            {
              label: 'Humbucker',
              value: '4-3',
              children: [
                {
                  label: '经典输出',
                  value: '4-3-1',
                },
                {
                  label: '中等输出',
                  value: '4-3-2',
                },
                {
                  label: '大输出',
                  value: '4-3-3',
                }
              ]
            },
            {
              label: '7/8 String',
              children: []
            },
            {
              label: 'P90',
              children: []
            },
            {
              label: 'Jaguar',
              children: []
            },
            {
              label: 'Jazzmaster',
              children: []
            },
            {
              label: 'Thinline',
              children: []
            },
            {
              label: 'Bass',
              children: [
                {
                  label: 'P Bass'
                },
                {
                  label: 'Jazz Bass'
                },
                {
                  label: 'PJ Bass'
                }
              ]
            },
            {
              label: '压电拾音器',
              children: []
            },
            {
              label: 'MIDI拾音器',
              children: []
            }
          ]
        },
        {
          label: '拾音器盖/框',
          value: '5',
          children: []
        },
        {
          label: '电路前级/Boost',
          value: '6',
          children: []
        },
        {
          label: '电位器/电位器帽',
          value: '7',
          children: [
            {
              label: '电位器',
              value: '7-1',
              children: []
            },
            {
              label: '电位器帽',
              value: '7-2',
              children: []
            }
          ]
        },
        {
          label: '档位器/档位器帽',
          value: '8',
          children: [
            {
              label: '档位器',
              value: '8-1',
              children: []
            },
            {
              label: '档位器帽',
              value: '8-2',
              children: []
            }
          ]
        },
        {
          label: '电容',
          value: '9',
          children: []
        },
        {
          label: '输出接口/接口垫片',
          value: '10',
          children: [
            {
              label: '输出接口',
              value: '10-1',
              children: []
            },
            {
              label: '船型接口',
              value: '10-2',
              children: []
            },
            {
              label: '接口垫片',
              value: '10-3',
              children: []
            }
          ]
        },
        {
          label: '焊接线材/屏蔽',
          value: '11',
          children: []
        },
        {
          label: '琴弦/背带',
          value: '12',
          children: []
        },
        {
          label: '卷弦器/相关配件',
          value: '13',
          children: [
            {
              label: '卷弦器',
              value: '13-1',
              children: []
            },
            {
              label: '手柄',
              value: '13-2',
              children: []
            },
            {
              label: '螺丝',
              value: '13-3',
              children: []
            }
          ]
        },
        {
          label: '琴桥/相关配件',
          value: '14',
          children: [
            {
              label: '琴桥',
              value: '14-1',
              children: []
            },
            {
              label: '拉弦板',
              value: '14-2',
              children: []
            },
            {
              label: '摇杆',
              value: '14-3',
              children: []
            },
            {
              label: '琴鞍',
              value: '14-4',
              children: []
            },
            {
              label: '压弦块',
              value: '14-5',
              children: []
            },
            {
              label: '立柱螺丝',
              value: '14-6',
              children: []
            },
            {
              label: '立柱底座',
              value: '14-7',
              children: []
            },
            {
              label: '挂钩',
              value: '14-8',
              children: []
            },
            {
              label: '弹簧',
              value: '14-9',
              children: []
            },
            {
              label: '零点恢复',
              value: '14-10',
              children: []
            },
            {
              label: '螺丝',
              value: '14-11',
              children: []
            }
          ]
        },
        {
          label: '琴头钟盖',
          value: '15',
          children: []
        },
        {
          label: '琴枕',
          value: '16',
          children: []
        },
        {
          label: '压弦扣/穿弦扣',
          value: '17',
          children: []
        },
        {
          label: '背带钉',
          value: '18',
          children: []
        },
        {
          label: '钢牌/角度垫片',
          value: '19',
          children: []
        },
        {
          label: '螺丝/弹簧',
          value: '20',
          children: []
        },
        {
          label: '电池仓',
          value: '21',
          children: []
        },
        {
          label: '调节工具/扳手',
          value: '22',
          children: []
        },
        {
          label: '护板/背板/支架',
          value: '23',
          children: [
            {
              label: 'Strat',
              value: '23-1',
              children: [
                {
                  label: '屏蔽层',
                  value: '23-1-1',
                },
                {
                  label: '单双',
                  value: '23-1-2',
                },
                {
                  label: '单单单',
                  value: '23-1-3',
                },
                {
                  label: '单单双',
                  value: '23-1-4',
                },
                {
                  label: '双双',
                  value: '23-1-5',
                },
                {
                  label: '背板',
                  value: '23-1-6',
                }
              ]
            },
            {
              label: 'Gibson',
              value: '23-2',
              children: [
                {
                  label: '护板',
                  value: '23-2-1',
                },
                {
                  label: '支架',
                  value: '23-2-2',
                },
                {
                  label: '后盖板',
                  value: '23-2-3',
                }
              ]
            },
            {
              label: 'Humbucker',
              value: '23-3',
              children: [
                {
                  label: '经典输出',
                  value: '23-3-1',
                },
                {
                  label: '中等输出',
                  value: '23-3-2',
                },
                {
                  label: '大输出',
                  value: '23-3-3',
                }
              ]
            },
          ]
        },
        {
          label: '文化产品',
          value: '24',
          children: [
            {
              label: '项链',
              value: '24-1',
              children: []
            },
            {
              label: '帽子',
              value: '24-2',
              children: []
            },
            {
              label: '衣服',
              value: '24-3',
              children: []
            },
            {
              label: '茶杯垫',
              value: '24-4',
              children: []
            }
          ]
        }
      ],
      funchild1List: [],
      funchild2List: [],
      showSearch: false,
      input: '',
    }
  },
  mounted() {
    this.getBrandList()
  },
  methods: {
    handleChange(val) {
      let first = val[0] || ""
      let second = val[1] || ""
      let third = val[2] || ""
      console.log("brandListAll====", this.brandListAll)
      console.log("handleChange====", first, second, third)
      var tabsList = {}
      var nextChild = {}
      this.brandListAll.forEach((item, index) => {
        if (item.categoryCode == first) {
          tabsList = this.brandListAll[index]
        }
      })
      let secondIndex = ""
      if (second) {
        tabsList.child.forEach((item, index) => {
          if (item.categoryCode == second) {
            nextChild = item
            secondIndex = index
          }
        })
      }
      let thirdIndex = ""
      if (third) {
        nextChild.child.forEach((item, index) => {
          if (item.categoryCode == third) {
            thirdIndex = index
          }
        })
      }
      let params = JSON.stringify(tabsList)
      this.$router.push({
        name: 'brand',
        state: {
          params,
          isTopClick: 'yes',
          secondIndex: secondIndex,
          thirdIndex: thirdIndex
        }
      })
    },
    goHome() {
      // window.location.href = "https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-24549763422.2.d8e5469fL1ppUc&id=683723227638"
      // return
      this.$router.push({
        name: 'home'
      })
    },
    onsearch() {
      this.showSearch = !this.showSearch
    },
    invisible() {
      this.tabsList.forEach((item) => {
        item.showFun = false
      })
      this.num = -1
    },
    scrollTo(tab, index) {
      this.num = index
      this.tabsList.forEach((v) => {
        v.showFun = false
      })
      if (index == 0 || index == 1) {
        this.tabsList[index].showFun = true
      }
      this.funchild1List = []
      this.funchild2List = []
      if (tab.id == 2) {
        this.realList = JSON.parse(JSON.stringify(this.functionList))
      } else if (tab.id == 1) {
        const transformedArray = this.transformData(this.brandListAll)
        this.realList = transformedArray
      } else if (tab.id == 6) {
        this.$router.push({
          name: 'aboutus'
        })
      } else {
        tab.showFun = false
        if (tab.id == 3 || tab.id == 4 || tab.id == 5) {
          // 维修视频or教学视频
          if (this.$router.currentRoute._value.name == 'home') {
            this.$emit('scroll', tab.id)
          } else {
            this.$router.push({
              name: 'home',
              state: {
                scrollFlag: 'vedio'
              }
            })
          }
        }
      }
    },
    transformData(arr) {
      return arr.map(item => ({
        value: item.categoryCode,
        label: item.categoryName,
        ...(item.child && item.child.length > 0 ? { children: this.transformData(item.child) } : {})
      }));
    },
    chooseFunction(type, fun, index) {
      console.log(1, this.funchild1List)
      if (type == 1) {
        this.realList.forEach((item) => {
          item.choosed = false
        })
        this.realList[index].choosed = true
      }
      if (fun.child && fun.child.length > 0) {
        if (type == 1) {
          this.funchild1List = fun.child
          this.funchild2List = [];
        }
        if (type == 2) {
          this.funchild2List = fun.child
        }
        console.log(2, this.funchild1List)
      } else {
        if (type == 1) {
          this.funchild1List = [];
          this.funchild2List = [];
        }
        if (type == 2) {
          this.funchild2List = [];
        }
      }
      console.log(3, this.funchild1List)
      if (type == 2 && this.funchild1List && this.funchild1List.length > 0) {
        this.funchild1List.forEach((item) => {
          item.choosed = false
        })
        this.funchild1List[index].choosed = true
      }
      if (type == 3 && this.funchild2List && this.funchild2List.length > 0) {
        this.funchild2List.forEach((item) => {
          item.choosed = false
        })
        this.funchild2List[index].choosed = true
      }
      fun.choosed = true
    },
    getBrandList() {
      Api.getBrandList().then((res) => {
        if (res) {
          this.brandListAll = res
          if (this.brandListAll.length > 8) {
            this.showMore = true
            this.brandList = this.brandListAll.slice(0, 8)
          } else {
            this.brandList = this.brandListAll
          }
          this.brandList.forEach(v => {
            v.showMsg = false
          })
          this.tableData = res
          this.pagination.total = res.total
        } else {
          this.tableData = []
          this.pagination.total = 0
        }
      }).catch(() => { })
    },
    search() {
      let searchmsg = this.input
      this.$router.push({
        name: 'search',
        state: {
          searchmsg
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.el-input__wrapper {
  background-color: none !important;
}

.middle {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
}

.tab:last-child {
  .tabText {
    position: relative;
    margin-top: 6px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.tabtextPadding {
  padding-right: 10px !important;
}

.tabs {
  // flex: 1;
  display: flex;
  padding: 11px 0 5px 0;
  // height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-family: 'roundblack';

  .tab {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
    color: #fff;
    position: relative;
    border-bottom: 6px solid #000;
    justify-content: center;

    .tabText {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      font-family: 'roundblack';
      padding: 0 22px;
      border-left: 1px solid rgba(255, 255, 255, 0.4);

      .left {
        display: flex;
        flex-direction: column;
      }

      .right {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 20px;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-items: center;
      }

      .arrordown {
        font-size: 8px;
      }

      .chinawen {
        color: #fcecc0;
        margin-bottom: 4px;
      }
    }
  }

  .tabactive {
    border-bottom: 4px solid #E7931C !important;
  }

  .detail {
    display: flex;
    position: absolute;
    left: 0;
    top: 73px;
    z-index: 999;
    height: 340px;
    color: #fff;
    border-radius: 4px;

    ::v-deep .el-cascader-node__prefix {
      display: none;
    }

    ::v-deep .el-cascader-node {
      padding: 0 !important;
    }

    ::v-deep .el-scrollbar {
      min-width: 180px;
      color: #fff;
      border: none !important;

      .active {
        color: #fcecc0 !important;
      }
    }

    ::v-deep .el-cascader-node__label{
      font-size: 18px;
    }

    ::v-deep .el-scrollbar:first-child {
      background-color: black;
    }

    ::v-deep .el-cascader-panel.is-bordered {
      border: none !important;
    }

    ::v-deep .el-scrollbar:nth-child(2) {
      min-width: 180px;
      background-color: rgba(0, 0, 0, 0.9);

      .active {
        color: #fcecc0 !important;
      }
    }

    ::v-deep .el-scrollbar:last-child {
      min-width: 180px !important;
      background-color: rgba(0, 0, 0, 0.8);

      .active {
        color: #fcecc0 !important;
      }
    }

    .funlist {
      display: flex;
      flex-direction: column;
      height: 360px;
      overflow-y: auto;

      .fun1Title {
        text-wrap: nowrap;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        text-align: left;
        font-size: 17px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #FFFFFF;
      }

      .active {
        color: #E7931C !important;
        background: #FFFFFF;

        .fun1Title {
          color: #E7931C !important;
        }
      }

      .funVo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 15px;
        // min-height: 42px;
        color: #fff;
        margin-right: 4px;

        .ricon {
          font-size: 11px;
          margin-left: 8px;
          margin-top: 2px;
        }
      }
    }
  }
}

.logoPart {
  width: 1083px;
  height: 165px;
  margin-top: 13px;
}

.comtopPart {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;

  .search {
    margin-left: 6px;
    min-width: 165px;
    height: 100%;
    background-color: #000;
    display: flex;
    align-items: center;

    .searchIcon {
      font-size: 22px;
      margin: 2px 0 0 12px;
      color: #fff;
    }

    .searchInput {
      width: 141px;
      height: 32px;
      font-size: 20px;
      border: none !important;

      ::v-deep .el-input__wrapper {
        background-color: #4D4D4D !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 4px !important;
        padding: 0 11px;
      }

      ::v-deep .el-input__inner {
        color: #fff !important;
      }
    }
  }
}

.all {
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>