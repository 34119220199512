<template>
  <div class="all">
    <TopPart class="tabsAll">
    </TopPart>
    <div class="logoPart">
      <!-- <img :src="product.banner" class="logo" /> -->
      <div class="logoname" :data-content="product.productName">
        <div>{{ product.productName }}</div>
      </div>
    </div>
    <div class="prdPart">
      <div class="pduImgPart">
        <el-image :src="attributeUrl" fit="cover" class="pduImg" />
        <!-- <el-image :src="attributeUrl" :zoom-rate="1.2" :preview-src-list="imageList" :initial-index="imgIndex" fit="cover"
          class="pduImg" /> -->
      </div>
      <div class="right">
        <!-- <div class="Productname">Product</div> -->
        <div class="name">{{ product.productName }}</div>
        <div class="detailDescription">{{ product.detailDescription }}</div>
        <div class="lablePart">
          <div class="lable">Price：</div>
          <div class="price">¥ {{ realPrice }}</div>
        </div>
        <div class="lablePart vorderBot">
          <div class="lable">Color：</div>
          <div class="attribute">
            <el-select v-model="formData.attribute" filterable placeholder="Select" @change="attributeChange">
              <el-option v-for="item in attributeList" :key="item.value" :label="item.name" :value="item.value" />
            </el-select>
          </div>
        </div>
        <div class="buyPart">
          <div class="buy" @click="buy(product.briefIntroduction)">
            <el-icon class="buyIcon">
              <ShoppingCartFull />
            </el-icon>
            Buy now
          </div>
        </div>
      </div>
    </div>
    <div class="detailPart">
      <el-tabs v-model="columnCode" class="tabs" @tab-click="handleClick">
        <transition v-for="(column, index) in columnList" :key="index">
          <el-tab-pane :name="column.columnCode">
            <template #label>
              <span class="custom-tabs-label">
                <el-icon v-if="index === 0">
                  <div class="iconfont">&#xe61f;</div>
                </el-icon>
                <el-icon v-if="index === 1">
                  <div class="iconfont">&#xe61e;</div>
                </el-icon>
                <el-icon v-if="index === 2">
                  <div class="iconfont">&#xe61d;</div>
                </el-icon>
                <el-icon v-if="index === 3">
                  <div class="iconfont">&#xe620;</div>
                </el-icon>
                <span>{{ column.columnName }}</span>
              </span>
            </template>
            <div class="columnTitle">{{ column.columnTitle }}</div>
            <div v-html="column.columnDesc" class="columnDesc"></div>
            <template v-if="!column.columnVideo">
              <div v-for="(item, index2) in column.resourceFileList" :key="index2" class="imagevo">
                <el-image class="child" :src="item" :fit="fit"></el-image>
              </div>
            </template>
            <template v-else>
              <div v-for="(item, index3) in column.columnVideo" :key="index3" class="imagevo">
                <video ref="myvideo" class="child" poster="" controls :src="item"></video>
              </div>
            </template>
          </el-tab-pane>
        </transition>
      </el-tabs>
    </div>
    <mapPart style="background: rgba(0,0,0,0.6);">
    </mapPart>
  </div>
</template>
<script>
import Api from '@/api/api'
import mapPart from '@/components/map'
import TopPart from '@/components/TopPart'
export default {
  components: {
    mapPart,
    TopPart
  },
  data() {
    return {
      formData: {
        attribute: ""
      },
      columnCode: '',
      columnList: [],
      product: {},
      attributeList: [],
      imgIndex: 0,
      attributeUrl: '',
      imageList: [],
      productCode: '',
      realPrice: 0,
      orgAttributeList: [],
      vedioList: []
    }
  },
  mounted() {
    window.scrollTo({
      top: 0
    });
    this.productCode = history.state.productCode
    this.getPduDetail() // 获取产品详情
  },
  methods: {
    getPduDetail() {
      Api.productDetail({ productCode: this.productCode }).then((res) => {
        if (res) {
          this.product = res
          // 属性切换图片处理
          this.imageList = res.attributeList.map((v) => { return v.attributeImage })
          // 属性处理
          this.orgAttributeList = res.attributeList
          this.attributeList = res.attributeList.map((v) => {
            return {
              value: v.id,
              name: v.attributeName
            }
          })
          this.formData.attribute = res.attributeList[0].id
          this.realPrice = Number(res.price) + Number(res.attributeList[0].diffPrice)
          this.attributeUrl = this.imageList[0]
          // 栏目处理
          this.columnList = res.columnList
          for (let i = 0; i < this.columnList.length; i++) {
            let column = this.columnList[i]
            console.log(column)
            if (column.resourceFile) {
              column.resourceFileList = column.resourceFile.split(";")
            }
            // 视屏处理
            if (column.columnVideo) {
              column.columnVideo = column.columnVideo.split(";")
              console.log(column.columnVideo)
            }
          }
          this.columnCode = this.columnList[0].columnCode
          console.log(this.columnCode, 99999999);
        }
      }).catch(() => { })
    },
    attributeChange() {
      console.log("选中:", this.formData.attribute);
      for (let i = 0; i < this.orgAttributeList.length; i++) {
        var item = this.orgAttributeList[i]
        if (item.id == this.formData.attribute) {
          this.attributeUrl = item.attributeImage
          this.realPrice = Number(this.product.price) + Number(item.diffPrice)
        }
      }
      console.log("选中:", this.attributeUrl);
    },
    scrollTo(id) {
      if (id == 2) {
        this.showFun = !this.showFun
      }
    },
    buy(e) {
      window.location.href = e
    }
  },
}
</script>
<style lang="scss" scoped>
.all {
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-image: url('@/assets/bannerbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;
  background-color: rgba(0, 0, 0, 0.7);
}

.logoPart {
  width: 100%;
  height: 135px;
  line-height: 78px;
  position: relative;
  background: rgba(0, 0, 0, 0.7);

  .logo {
    width: 100%;
    object-fit: cover;
    // -webkit-mask-image: linear-gradient(180deg, rgb(255, 255, 255) -90%, rgba(255, 255, 255, 0) 100%);
  }

  .logoname::after {
    content: attr(data-content);
    /* 获取原始文本内容并显示为伪元素的内容 */
    position: absolute;
    /* 设置伪元素相对于其所在位置进行定位 */
    top: 40px;
    /* 调整伪元素与原始文本之间的距离 */
    left: 0;
    right: 0;
    margin: auto;
    /* 水平居中 */
    transform: scaleY(-1);
    /* 反转伪元素的 Y 轴，形成倒影效果 */
    // color: rgba(255, 255, 255, 0.8);
    color: transparent;
    /* 设置伪元素的颜色（白色）及不透明度 */
    font-size: inherit;
    /* 继承原始文本的字体大小 */
    letter-spacing: inherit;
    /* 继承原始文本的字符间距 */
    line-height: inherit;
    /* 继承原始文本的行高 */
    pointer-events: none;
    /* 确保点击事件只作用于原始文本而非伪元素 */
    background: linear-gradient(to bottom, transparent 20%, #fff);
    /* 这里的rgba表示红、绿、蓝三原色及其对应的透明度 */
    -webkit-background-clip: text;
    /* Safari/Chrome */
    -moz-background-clip: text;
    /* Firefox */
  }

  .logoname {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 39px;
    font-family: 'AlimamaFangYuanTiVF';
    font-weight: 500;
    color: #FFFFFF;

    .briefIntroduction {
      margin-top: 10px;
      font-size: 25px;
    }
  }
}

::v-deep .el-tabs__content {
  padding: 32px 0;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.prdPart {
  padding: 14px 0 18px 0;
  margin-top: -10px;
  display: flex;
  width: 100%;
  height: 615px;
  border-bottom: 4px solid;
  border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(252, 236, 192, 1), rgba(255, 255, 255, 0)) 4 4;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.85) 49%, rgba(0, 0, 0, 0.4) 100%);

  ::v-deep .el-input__wrapper {
    background: none !important;
    height: 30px;
    font-size: 15px;
    font-family: 'AlibabaPuHuiTi_3_55_Regular';
    color: #FFFFFF;
  }

  ::v-deep .el-input__inner {
    color: #fff;
  }

  .right {
    padding: 0 29px 0 46px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;

    .Productname {
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: #fff;
      letter-spacing: 8px
    }

    .name {
      font-size: 27px;
      font-family: AlibabaPuHuiTi_3_85_Bold;
      color: #FFFFFF;
      font-weight: bold;
      padding: 28px 0 22px 0;
      line-height: 38px;
      border-bottom: 4px solid;
      border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(252, 236, 192, 1), rgba(255, 255, 255, 0)) 4 4;
    }

    .vorderBot {
      border-bottom: 1px dashed rgba(252, 236, 192, 0.5);
    }

    .lablePart {
      width: 100%;
      min-height: 60px;
      display: flex;
      align-items: center;
      border-top: 1px dashed rgba(252, 236, 192, 0.5);

      .lable {
        text-align: left;
        font-size: 15px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #FFFFFF;
        flex: 1;
      }

      .price {
        font-size: 24px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #EF0000;
        text-align: right;
      }
    }

    .buyPart {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .buy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 45px;
        background: #E7931C;
        font-size: 24px;
        font-family: 'AlibabaPuHuiTi_3_55_Regular';
        color: #FFFFFF;
        margin-top: 52px;

        .buyIcon {
          margin-right: 10px;
        }
      }
    }


    .detailDescription {
      font-size: 20px;
      margin: 30px 0 48px 0;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box;
      -webkit-line-clamp: 4; // 超出多少行
      -webkit-box-orient: vertical;
      line-height: 25px;
    }

    .attribute {
      width: 210px;
      height: 30px;

      ::v-deep .el-select {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        // border: 1px solid #FFFFFF;
      }
    }
  }

  .pduImgPart {
    width: 854px;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.3);

    .pduImg {
      width: 563px;
      height: 563px;
    }
  }
}

.detail {
  display: flex;

  .funlist {
    display: flex;
    flex-direction: column;

    .funVo {
      display: flex;
    }
  }
}


.detailPart {
  padding: 0 180px;
  background: rgba(0, 0, 0, 0.6);

  ::v-deep .el-icon svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-right: 8px;
  }
}

::v-deep .el-tabs__item {
  font-size: 24px;
  font-family: 'AlimamaFangYuanTiVF, AlimamaFangYuanTiVF';
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
}

::v-deep .el-tabs__nav {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
}

::v-deep .el-tabs__nav .is-active{
  color: #E7931C !important;
}

.tabs {
  .iconfont{
    font-size: 19px;
    margin-right: 12px;
  }
  .columnTitle {
    width: 100%;
    text-align: left;
    font-size: 27px;
    font-family: 'AlibabaPuHuiTi_3_85_Bold';
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 36px;
  }

  .columnDesc {
    text-align: left;
    font-size: 21px;
    font-family: 'AlibabaPuHuiTi_3_55_Regular';
    color: rgba(255, 255, 255, 0.7);
    line-height: 30px;
    margin-bottom: 36px;
    letter-spacing: 2px;

    ::v-deep .el-tabs__content {
      font-weight: normal !important;
    }
  }

  .imagevo {
    // height: 710px;
    padding: 36px 0;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 36px;

    .child {
      width: 750px;
      // height: 482px;
    }
  }
}
</style>