import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"
import home from '@/views/home.vue'
import brand from '@/views/brand.vue'
import product from '@/views/product.vue'
import search from '@/views/search.vue'
import aboutus from '@/views/aboutus.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/brand',
    name: 'brand',
    component: brand
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
