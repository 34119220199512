import requestPure from '@/utils/requestPure'

// 订单中心相关接口
const Api = {
  // 门店映射关系-查询数据
  getBrandList(data) {
    return requestPure.post('/music/category/list', data)
  },
  // 产品详情
  productDetail(data) {
    return requestPure.post('/music/product/product/' + data.productCode + '/detail')
  },
  // 分类下产品
  getProductList(data) {
    return requestPure.post('/music/menu/' + data.categoryCode + '/page', data)
  },
  // banner获取
  getBannerList(data) {
    return requestPure.post('/music/resource/list/page', data)
  },
  // 查询所有产品
  getAllProductList(data) {
    return requestPure.post('/music/product/list', data)
  },


}

export default Api


