
import axios from 'axios'
import store from '@/store'

const debug = process.env.NODE_ENV !== 'production'

// create an axios instance
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000

// create an axios instance
const service = axios.create({
  baseURL: 'https://api.musicweapons.cn', // url = base url + request url
  timeout: 100000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['X-RD-Request-APIToken'] = store.getters.token
    }
    if (debug) {
      // console.log(config)
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (debug) {
      // console.log(res)
    }
    if (res.msg !== 'success') {
      return Promise.reject('error')
    } else {
      return response.data.body
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
