<template>
  <div class="all">

    <TopPart @scroll="scrollToVedio">
    </TopPart>

    <div class="bannerPart">
      <template v-if="bannerList.length > 1">
        <img :src="require('@/assets/left.png')" class="leftBanner" @click="leftBanner" style="zIndex:999" />
        <div class="bannerNew">
          <div class="bannerImagesAll">
            <img :src="bannerList[lastBannerIndex].path" class="bannerLast"
              @click="goBanner(bannerList[lastBannerIndex])" />
            <img :src="bannerList[nowBannerIndex].path" class="bannerMiddle"
              @click="goBanner(bannerList[nowBannerIndex])" />
            <img :src="bannerList[nextBannerIndex].path" class="bannerNext"
              @click="goBanner(bannerList[nextBannerIndex])" />
          </div>
        </div>
        <img :src="require('@/assets/right.png')" class="rightBanner" @click="rightBanner" style="zIndex:999" />
      </template>
      <!-- <template v-if="bannerList.length > 1">
        <el-carousel :interval="4000" type="card" height="420px">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <el-image class="medium" :src="item.path" :fit="fit"></el-image>
          </el-carousel-item>
        </el-carousel>
      </template> -->
      <template v-else>
        <img :src="bannerList.length > 0 ? bannerList[0].path : ''" class="banner" />
      </template>
    </div>

    <div class="pptvo" id="brand">
      <img :src="require('@/assets/pptimage.png')" class="pptimage" />
      <div class="pptname">
        <div class="cname">运营品牌</div>
        <div class="ename">Brands</div>
      </div>
    </div>

    <div class="brandPart">
      <div class="brandMain">
        <div class="brandScroll" style="overflow: auto">
          <div v-for="(item, index) in brandList" :key="index" class="brandList"
            @mouseover="showOverlay = true; currentBrandIndex = index" @mouseleave="showOverlay = false">
            <div class="brandVo" @click="item.showMsg = !item.showMsg">
              <img :src="item.imageHome" class="logo" />
              <div class="entitle">{{ item.categoryName }}</div>
              <div class="brandMsg" v-if="showOverlay && currentBrandIndex === index"
                @click="goBrand(currentBrandIndex)" :style="{ display: overlayDisplay }">
                <div class="topMsg">{{ item.categoryName }}</div>
                <div class="midMsg">{{ item.remark }}</div>
                <div class="botBout" @click="goBrand(index)">
                  <div class="view">进入品牌</div>
                </div>
              </div>
            </div>
            <transition name="fade">
            </transition>
          </div>
        </div>
        <div v-if="brandList.length >= 8" @click="showMoreFun" class="smcha">
          <div>{{ showMore ? 'See More' : 'Up' }}</div>
          <el-icon class="cha" v-if="showMore">
            <ArrowDown />
          </el-icon>
          <el-icon class="cha" v-else>
            <ArrowUp />
          </el-icon>
        </div>
      </div>
    </div>
    <div class="pptvo" id="work">
      <img :src="require('@/assets/pptimage.png')" class="pptimage" />
      <div class="pptname">
        <div class="cname">维修定制视频</div>
        <div class="ename">Repair and Customization</div>
      </div>
    </div>
    <div class="vedioPart">
      <el-carousel :interval="5000" arrow="always" indicator-position="none" :autoplay="false" :height="vedioheight">
        <el-carousel-item v-for="item in bannerTopimgs" :key="item" class="vedioLine">
          <div v-for="(itemVo, index) in item" :key="itemVo.title" class="vedio" @click="showVedio(itemVo)"
            @mouseover="activeIndex = index" @mouseout="activeIndex = -1">
            <div class="vedioImageAll">
              <div class="mask"></div>
              <div class="vedioMask" v-show="activeIndex === index">
                <el-image class="vedioPlay" :src="require('@/assets/vedioPlay.png')"></el-image>
              </div>
              <el-image class="vedioImage" :src="itemVo.path" :fit="fit">
              </el-image>
            </div>
            <div class="vedioTitle">
              <el-image class="playVedio" :src="require('@/assets/playVedio.png')"></el-image>
              <div class="right">{{ itemVo.title }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="pptvo" id="vedio">
      <img :src="require('@/assets/pptimage.png')" class="pptimage" />
      <div class="pptname">
        <div class="cname">客户案例视频</div>
        <div class="ename">Workshop Galleries</div>
      </div>
    </div>
    <div class="vedioPart">
      <el-carousel :interval="5000" arrow="always" indicator-position="none" :autoplay="false" :height="vedioheight">
        <el-carousel-item v-for="item in bannerBotimgs" :key="item" class="vedioLine">
          <div v-for="(itemVo, index) in item" :key="itemVo" class="vedio" @click="showVedio(itemVo)"
            @mouseover="activeIndex2 = index" @mouseout="activeIndex2 = -1">
            <div class="vedioImageAll">
              <div class="mask"></div>
              <div class="vedioMask" v-show="activeIndex2 === index">
                <el-image class="vedioPlay" :src="require('@/assets/vedioPlay.png')"></el-image>
              </div>
              <el-image class="vedioImage" :src="itemVo.path" :fit="fit">
              </el-image>
            </div>
            <div class="vedioTitle">
              <el-image class="playVedio" :src="require('@/assets/playVedio.png')"></el-image>
              <div class="right">{{ itemVo.title }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>


    <div class="pptvo">
      <img :src="require('@/assets/pptimage.png')" class="pptimage" />
      <div class="pptname">
        <div class="cname">联系我们</div>
        <div class="ename">Contact Us</div>
      </div>
    </div>

    <mapPart class="mapAll" @scroll="scrollToVedio">
    </mapPart>

    <div v-if='videoState'>
      <div class='mask' @click='masksCloseFun'></div>
      //弹窗
      <div class="videomasks">
        <video :src="videoUrl" width="500" autoplay="false" controls="controls" :state="viewVideo" ref="vueRef"></video>
      </div>
    </div>

  </div>
</template>
<script>
import Api from '@/api/api'
import VueSeamlessScroll from 'vue-seamless-scroll/src'
import mapPart from '@/components/map';
import TopPart from '@/components/TopPart';
import { shallowRef } from '@vue/reactivity'

export default {
  components: {
    VueSeamlessScroll,
    mapPart,
    TopPart
  },
  data() {
    return {
      bannerList: [],
      bannerTopimgs: [],
      bannerBotimgs: [],
      brandList: [],
      brandListAll: [],
      // 弹窗展示状态 默认false
      viewVideo: false,
      // 视频链接   这个大家可以写上自己的视频地址
      videoUrl: '',
      index: -1,
      count: 2,
      showMore: true,
      videoState: false,
      nowbannerIndex: 0,
      lastBannerIndex: 0,
      nextBannerIndex: 0,
      showOverlay: false,
      currentBrandIndex: null,
      activeIndex: -1,
      activeIndex2: -1,
      vedioheight: "360px"
    }
  },
  computed: {
    overlayDisplay() {
      return this.showOverlay ? 'block' : 'none';
    }
  },
  setup() {
    const map = shallowRef(null);
    return {
      map,
    }
  },
  mounted() {
    var windowWidth = window.innerWidth;
    var bl = windowWidth / 1440;
    this.vedioheight = Math.floor(360 * bl) + 'px';
    this.getBannerList()
    this.getBrandList()
    this.scrollFlag = history.state.scrollFlag || ''
    if (this.scrollFlag) {
      this.scrollToVedio(this.scrollFlag)
    }
  },
  methods: {
    scrollToVedio(id) {
      console.log('home-fromMap', id)
      if (id && id == 3) {
        this.$el.querySelector('#work').scrollIntoView()
      } else if (id && id == 6) {
        this.$router.push({
          name: 'aboutus'
        })
      } else if (id && (id == 4 || id == 5)) {
        this.$el.querySelector('#vedio').scrollIntoView()
      } else if (id && id == '1') {
        this.$el.querySelector('#brand').scrollIntoView()
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    getBrandList() {
      Api.getBrandList().then((res) => {
        if (res) {
          this.brandListAll = res
          if (this.brandListAll.length > 8) {
            this.showMore = true
            this.brandList = this.brandListAll.slice(0, 8)
          } else {
            this.brandList = this.brandListAll
          }
          this.brandList.forEach(v => {
            v.showMsg = false
          })
          this.tableData = res
          this.pagination.total = res.total
        } else {
          this.tableData = []
          this.pagination.total = 0
        }
      }).catch(() => { })
    },
    getBannerList() {
      // this.bannerList = [{ path: 'https://img2.baidu.com/it/u=517352261,2715444149&fm=253&fmt=auto&app=138&f=JPEG?w=749&h=500' },
      // { path: 'https://ss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=1195208757,228483416&fm=179&app=35&f=PNG?w=2000&h=360&s=7CA15A7EC98367744C03FFAC0300E00B' },
      // { path: 'https://t11.baidu.com/it/app=106&f=JPEG&fm=30&fmt=auto&u=2703683157%2C217493178?w=312&h=208&s=002AD51746E054AA8238D4FC0300D021' }]
      // this.lastBannerIndex = this.bannerList.length - 1
      // this.nowBannerIndex = 0
      // this.nextBannerIndex = this.nowBannerIndex + 1
      // return
      Api.getBannerList({ page: 1, rows: 100 }).then((res) => {
        if (res) {
          this.bannerList = res.records.filter(v => {
            return v.displayLocation == 1
          })
          this.lastBannerIndex = this.bannerList.length - 1
          this.nowBannerIndex = 0
          this.nextBannerIndex = this.nowBannerIndex + 1
          let bannerTopimgs = res.records.filter(v => {
            return v.displayLocation == 2
          })
          let bannerBotimgs = res.records.filter(v => {
            return v.displayLocation == 3
          })
          this.bannerTopimgs = this.scheduleListThree(bannerTopimgs)
          this.bannerBotimgs = this.scheduleListThree(bannerBotimgs)
        }
      }).catch(() => { })
    },
    showVedio(banner) {
      this.videoState = true
      this.videoUrl = banner.forwardParam
    },
    masksCloseFun() {
      this.videoState = false;
    },
    showMoreFun() {
      if (this.showMore) {
        this.brandList = this.brandListAll
        this.showMore = false
      } else {
        this.brandList = this.brandListAll.slice(0, 8)
        this.showMore = true
      }
    },
    showBrandMsg(index) {
      this.brandList[index].showMsg = true
    },
    brandInvisible() {
      this.brandList.forEach((item) => {
        item.showMsg = false
      })
    },
    showBrandDetail(index) {
      this.brandList[index].showMsg = true
      return
    },
    goBrand(index) {
      var tabsList = this.brandList[index]
      let params = JSON.stringify(tabsList)
      this.$router.push({
        name: 'brand',
        state: {
          params
        }
      })
    },
    rightBanner() {
      console.log('rightBanner')
      if (this.nowBannerIndex === this.bannerList.length - 1) {
        // 往右更迭已经是最后一个了
        this.nowBannerIndex = 0
        this.lastBannerIndex = this.bannerList.length - 1
        this.nextBannerIndex = this.nowBannerIndex + 1
      } else {
        this.nowBannerIndex = this.nowBannerIndex + 1
        this.lastBannerIndex = this.nowBannerIndex - 1
        if (this.nowBannerIndex + 1 == this.bannerList.length) {
          // 倒数第一张 
          this.nextBannerIndex = 0
        } else {
          this.nextBannerIndex = this.nowBannerIndex + 1
        }
      }
    },
    leftBanner() {
      console.log('leftBanner')
      if (this.nowBannerIndex === 0) {
        // 往左更迭已经是第一个了,防止下标为负数
        this.nowBannerIndex = this.bannerList.length - 1;
        this.lastBannerIndex = this.nowBannerIndex - 1;
        if (this.nowBannerIndex + 1 >= this.bannerList.length) {
          this.nextBannerIndex = 0
        } else {
          this.nextBannerIndex = this.nowBannerIndex + 1;
        }
      } else {
        this.nowBannerIndex = this.nowBannerIndex - 1;
        if (this.nowBannerIndex == 0) {
          this.lastBannerIndex = this.bannerList.length - 1;
        } else {
          this.lastBannerIndex = this.nowBannerIndex - 1;
        }
        this.nextBannerIndex = this.nowBannerIndex + 1;
      }
    },
    scheduleListThree(list) {
      let index = 0;
      let count = 3;
      let arrThree = [];
      let data = list;
      for (let i = 0; i < list.length; i++) {
        index = parseInt(i / count);
        if (arrThree.length <= index) {
          arrThree.push([]);
        }
        arrThree[index].push(data[i])
      }
      return arrThree
    },
    showImageHover(item) {
      item.showMsg = true
    },
    hideImageHover(item) {
      item.showMsg = false
    },
    goBanner(item) {
      Api.getAllProductList({
        page: 1,
        rows: 99,
        orderName: "",
        asc: true,
        skuCode: "",
        productName: item.forwardParam
      }).then((res) => {
        if (res && res.length > 0) {
          let productCode = res[0].productCode
          if (productCode) {
            this.$router.push({
              name: 'product',
              state: {
                productCode: productCode
              }
            })
          }
        }
      }).catch(() => { })
    },
  },
}
</script>
<style lang="scss" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 500px;
}

.bannerPart {
  // width: 100%;
  padding: 60px 120px 60px 120px;
  position: relative;
  background-image: url('@/assets/bannerbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;

  .medium {
    max-width: 816px;
    max-height: 512px;
    border-radius: 30px;
    border: 4px solid #FCECC0;
    object-fit: scale-down;
  }

  .leftBanner {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 20px;
    z-index: 100px !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .rightBanner {
    width: 48px;
    height: 48px;
    right: 20px;
    z-index: 100px !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .bannerNew {
    display: flex;
    align-items: center;
    justify-content: center;

    .bannerImagesAll {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bannerMiddle {
      z-index: 99;
      width: 792px;
      height: 488px;
      box-shadow: 3px 3 12px 0px rgba(0, 0, 0, 0.6);
      border-radius: 30px;
      border: 4px solid #FCECC0;
    }

    .bannerLast {
      z-index: 1;
      width: 439px;
      height: 270px;
      margin-right: -303px;
      box-shadow: 3px 3 12px 0px rgba(0, 0, 0, 0.6);
      border-radius: 30px;
      border: 4px solid #FCECC0;
    }

    .bannerNext {
      z-index: 1;
      width: 439px;
      height: 270px;
      margin-left: -303px;
      box-shadow: 3px 3 12px 0px rgba(0, 0, 0, 0.6);
      border-radius: 30px;
      border: 4px solid #FCECC0;
    }
  }

  .banner {
    width: 90%;
    height: 350px;
    // border-radius: 10px;
  }

  ::v-deep .el-carousel__mask {
    background: none !important;
  }

}

.all {
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pptvo {
  width: 100%;
  height: 188px;
  background: linear-gradient(180deg, #272727 0%, #232323 23%, #020202 100%);
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .pptimage {
    position: absolute;
    width: 617px;
    height: 149px;
    top: 50%;
    transform: translateY(-50%);
    left: 160px;
  }

  .pptname {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 750px;
    height: 140px;
    border-top: 4px solid;
    border-bottom: 4px solid;
    border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(252, 236, 192, 1), rgba(255, 255, 255, 0)) 4 4;
    // z-index: 99;

    .cname {
      font-size: 36px;
      font-family: 'AlibabaPuHuiTi_3_85_Bold';
      color: #FFFFFF;
      font-weight: bold;
      letter-spacing: 4px;
      opacity: 0.75;
    }

    .ename {
      font-size: 36px;
      font-family: 'AlibabaPuHuiTi_3_55_Regular';
      color: #FCECC0;
      opacity: 0.75;
    }
  }
}

.brandPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 50px;
  align-items: center;
  color: #fff;
  padding: 56px 40px;
  font-family: 'batmfa';
  background-image: url('@/assets/brandbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;

  .brandMain {
    // background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    .smcha {
      display: flex;
      margin-top: 54px;
      width: 122px;
      justify-content: center;
      align-items: center;
      height: 30px;
      background: rgba(128, 128, 128, 0.11);
      border-radius: 3px;
      border: 1px solid #FCECC0;
      color: #fcecc0;
      font-size: 15px;
      font-family: 'AlibabaPuHuiTi_3_55_Regular';
      color: #FFF4D6;

      .cha {
        margin-left: 14px;
      }
    }

    .title {
      font-family: "AlibabaPuHuiTi-3-55-Regular";
    }

    .brandScroll {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .brandList {
        margin: 11px 0;

        .brandMsg {
          cursor: pointer;
          z-index: 999;
          position: absolute;
          background: rgba(0, 0, 0, 0.7);
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .topMsg {
            margin: 10px 0 8px 0;
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-family: 'AlibabaPuHuiTi_3_85_Bold';
            color: #FFFFFF;
          }

          .midMsg {
            height: 83px;
            line-height: 17px;
            text-align: left;
            justify-content: center;
            font-size: 12px;
            font-family: 'AlibabaPuHuiTi_3_55_Regular';
            color: #FFFFFF;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box;
            -webkit-line-clamp: 5; // 超出多少行
            -webkit-box-orient: vertical;
            margin: 0 20px;
          }

          .botBout {
            width: 100%;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            margin-top: 8px;

            .view {
              width: 150px;
              height: 26px;
              line-height: 26px;
              background: #404040;
              border-radius: 3px;
              font-size: 12px;
              font-family: 'AlibabaPuHuiTi_3_55_Regular';
              color: #FCECC0;
            }
          }
        }

        .brandVo {
          width: 257px;
          height: 188px;
          margin-right: 22px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          border: 4px solid #fcecc0;
          position: relative;

          .another {
            bottom: 0;
          }

          .logo {
            width: 100%;
            height: 184px;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            border-bottom: 2px solid;
            border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(252, 236, 192, 1), rgba(255, 255, 255, 0)) 2 2;
          }

          .entitle {
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-family: 'AlibabaPuHuiTi_3_55_Regular';
            color: #FFFFFF;
            justify-content: center;
          }
        }
      }
    }


  }

  .en {
    width: 100%;
    font-size: 60px;
    font-weight: bold;
    font-family: "AlibabaPuHuiTi-3-55-Regular";
    text-align: center;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
  }
}

.vedioImageAll {
  position: relative;
  height: 203px;

  .vedioMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* 使用rgba设置透明黑色背景 */
    z-index: 1;
    /* 遮罩层需要高于内容层 */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;

    .vedioPlay {
      width: 48px;
      height: 48px;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: .6;
}

// 内容层 z-index要比遮罩大，否则会被遮盖
.videomasks {
  max-width: 1200px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.mapAll {
  padding: 30px;
  background-image: url('@/assets/mapbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;
}

.vedioPart {
  // height: 360px;
  // padding: 20px 0;
  background-image: url('@/assets/bannerbg.jpg');
  background-size: 100% 100%;

  // margin: 0 180px;
  ::v-deep .el-carousel__arrow {
    background: rgba(255, 255, 255, 0.5) !important;
  }

  ::v-deep .el-icon {
    color: #505357 !important;
    font-weight: bold !important;
    font-size: 28px !important;
  }

  .vedioLine {
    align-items: center;
    display: flex;
    justify-content: center;

    .vedio {
      display: flex;
      flex-direction: column;
      width: 368px;
      // height: 258px;
      overflow: hidden;
      margin-right: 18px;

      .mask {
        position: absolute;
        border-radius: 9px;
        /* 将遮罩定位在页面上 */
        top: 0;
        left: 0;
        width: 100%;
        height: 204px;
        background-color: rgba(0, 0, 0, 0.4);
        /* 设置黑色遮罩并指定透明度为0.5（取值范围为0到1）*/
      }

      .vedioImage {
        position: relative;
        width: 368px;
        height: 203px;
        overflow: hidden;
        border-radius: 9px;
      }

      .vedioTitle {
        min-height: 44px;
        display: flex;
        font-size: 15px;
        font-family: 'AlibabaPuHuiTi_3_65_Medium';
        color: #FFFFFF;
        text-align: left;
        margin-top: 4px;

        .playVedio {
          min-width: 20px;
          height: 17px;
          margin-right: 9px;
          margin-top: 2px;
        }

        .right {
          height: 100%;
          overflow: hidden;
          /* 超出隐藏 */
          text-overflow: ellipsis;
          /* 溢出显示用省略号 */
          display: -webkit-box;
          /* 作为弹性伸缩盒子模型显示。 */
          -webkit-box-orient: vertical;
          /* 设置伸缩盒子的子元素排列方式  从上到下垂直排列 */
          -webkit-line-clamp: 2;
          /* 显示的行数 */
        }
      }
    }
  }

}
</style>