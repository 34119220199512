<template>
  <div class="all">
    <TopPart class="tabsAll">
    </TopPart>
    <div class="topPart">
      <el-image class="brandBanner" :src="tabsList.imageList"></el-image>
      <div class="topr">
        <div class="title" :data-content="tabsList.categoryName">{{ tabsList.categoryName }}</div>
        <div class="desc">{{ tabsList.remark }}</div>
      </div>
    </div>
    <div class="tabs">
      <div class="left">产品中心：</div>
      <div class="right">
        <div class="topBrandClass">
          <div class="tab" @click="clickAllFun" :class="{ 'choosed': clickAll }">All</div>
          <div class='tab' :class="{ 'choosed': nowIndex1 == index }" v-for="(tab, index) in tabsList.child"
            :key="tab.categoryCode">
            <div @click="showClistclick(tab, index, 1)">{{ tab.categoryName }}
            </div>
            <el-icon v-if="tab.child && tab.child.length > 0" class="arrowdown">
              <ArrowDownBold />
            </el-icon>
          </div>
        </div>
        <div v-show="nowtab.showClist" class="childBrandClass">
          <div v-for="(item, index2) in nowtab.child" :key="index2">
            <div class="child" :class="{ 'textchoosed': item.choosed }" @click="showClistclick(item, index2, 2)">{{
        item.categoryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="totalRow">共<span class="sp">{{ totalRow }}</span>个产品</div>
    <div class="productList">
      <div class="prdvo" @click="goPduDetail(item)" v-for="(item, index) in productList" :key="index">
        <img :src="item.image" class="logo" width="255" height="255" />
        <div class="itembottom">
          <div class="name">{{ item.productName }}</div>
          <div class="desc">
            <div class="price">¥{{ item.price }}</div>
            <div class="go">More</div>
            <el-icon class="cha">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout="prev, pager, next"
        :page-count="totalCount" prev-text="上一页" next-text="下一页" class="pagination"></el-pagination>
    </div>
    <mapPart>
    </mapPart>
  </div>
</template>
<script>
import Api from '@/api/api'
import mapPart from '@/components/map'
import TopPart from '@/components/TopPart'
export default {
  components: {
    mapPart,
    TopPart
  },
  data() {
    return {
      // 总数据
      tableData: [],
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      tabsList: [],
      productList: [],
      nowIndex1: -1,
      nowIndex2: -1,
      nowtab: {},
      clickAll: false
    }
  },
  mounted() {
    window.scrollTo({
      top: 0
    });
    this.tabsList = JSON.parse(history.state.params)
    console.log("brand-state", history.state)
    this.tabsList.child.forEach((v) => {
      v.showClist = false
      v.choosed = false
    })
    this.categoryCode = this.tabsList.categoryCode
    // this.getBrandList()
    this.getProductList(1)
    if (history.state.isTopClick == 'yes') {
      // 是顶部点击品牌跳转
      console.log("brand-isTopClick", this.tabsList, history.state.secondIndex)
      let { secondIndex, thirdIndex } = history.state
      if (secondIndex || secondIndex == 0) {
        this.showClistclick(this.tabsList.child[secondIndex], secondIndex, 1)
      }
      if (thirdIndex || thirdIndex === 0) {
        this.showClistclick(this.tabsList.child[secondIndex].child[thirdIndex], thirdIndex, 2)
      }
      if (!secondIndex && secondIndex !== 0 && !thirdIndex && thirdIndex != 0) {
        this.clickAllFun()
      }
    }
  },
  methods: {
    clickAllFun() {
      this.clickAll = true
      this.nowIndex1 = -1
      this.nowIndex2 = -1
      this.nowtab.showClist = false
      this.categoryCode = this.tabsList.categoryCode
      this.getProductList(1)
    },
    async getBrandList() {
      Api.getBrandList().then((res) => {
        if (res) {
          this.tabsList = res[1]
          this.getProductList()
        }
      }).catch(() => { })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getProductList(val)
    },
    showClistclick(tab, index, type) {
      this.clickAll = false
      let listSecond = this.tabsList.child || []
      if (type == 1) {
        this.nowtab = tab
        this.nowIndex1 = index
        this.nowIndex2 = -1
        listSecond.forEach((tabVo) => {
          tabVo.showClist = false
          tabVo.choosed = false
        })
        tab.choosed = true
        this.categoryCode = listSecond[index].categoryCode
        this.index = index
        this.getProductList(1)
        if (tab.child && tab.child.length > 0) {
          tab.showClist = true
        } else {
          tab.showClist = false
        }
        if (listSecond.length > 0) {
          let child = listSecond[this.index].child || []
          child && child.length > 0 && child.forEach((tabVo) => {
            tabVo.choosed = false
          })
        }
      } else if (type == 2) {
        this.nowIndex2 = index
        let listThird = listSecond[this.index].child
        listThird.forEach((tabVo) => {
          tabVo.choosed = false
        })
        tab.choosed = true
        this.categoryCode = listThird[index].categoryCode
        this.getProductList(1)
      }
    },
    getProductList(page) {
      Api.getProductList({
        categoryCode: this.categoryCode,
        page: page,
        rows: 12,
        nesting: true
      }).then((res) => {
        if (res) {
          this.productList = res.records
          this.totalCount = res.totalPage
          this.totalRow = res.totalRow
        }
      }).catch(() => { })
    },
    goPduDetail(item) {
      this.$router.push({
        name: 'product',
        state: {
          productCode: item.productCode
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.pagination {
  width: 100%;
}

.all {
  background: #000;
  font-family: "AlibabaPuHuiTi-3-55-Regular";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/bannerbg.jpg');
  background-size: 100% 100%;
  background-repeat: repeat-y;
}

::v-deep .el-pagination {
  justify-content: flex-end !important;
}

::v-deep .el-pagination .el-pager li {
  background-color: transparent !important;
}

::v-deep .el-pagination button {
  background-color: transparent !important; //修改默认的背景色
}

::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

::v-deep.el-pagination .el-pager .number {
  font-size: 20px !important;
  color: #fff;
}

::v-deep.el-pagination .btn-next {
  font-size: 20px !important;
  color: #fff;
  margin-left: 20px;
}

::v-deep.el-pagination .btn-prev {
  font-size: 20px !important;
  color: #fff;
  margin-right: 20px;
}

::v-deep.el-pagination .is-active {
  color: #E7931C !important;
}


.title2 {
  height: 100px;
  line-height: 100px;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.topPart {
  width: 100%;
  position: relative;
  background: #000;

  .brandBanner {
    width: 100%;
    border-bottom: 4px solid;
    border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(252, 236, 192, 1), rgba(255, 255, 255, 0)) 4 4;
  }

  .topr {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 129px;
      font-family: 'AlibabaPuHuiTi_3_115_Black';
      color: #FFFFFF;
      line-height: 180px;
      letter-spacing: 13px;
      font-weight: bold;
    }
  }

  .desc {
    padding: 0 160px;
    font-size: 18px;
    font-family: 'AlimamaFangYuanTiVF';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    letter-spacing: 2px;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    display: -webkit-box;
    -webkit-line-clamp: 3; // 超出多少行
    -webkit-box-orient: vertical;
    margin-top: 90px;
  }

  .title::after {
    content: attr(data-content);
    /* 获取原始文本内容并显示为伪元素的内容 */
    position: absolute;
    /* 设置伪元素相对于其所在位置进行定位 */
    top: 110px;
    /* 调整伪元素与原始文本之间的距离 */
    left: 0;
    right: 0;
    margin: auto;
    /* 水平居中 */
    transform: scaleY(-1);
    /* 反转伪元素的 Y 轴，形成倒影效果 */
    // color: rgba(255, 255, 255, 0.8);
    color: transparent;
    /* 设置伪元素的颜色（白色）及不透明度 */
    font-size: inherit;
    /* 继承原始文本的字体大小 */
    letter-spacing: inherit;
    /* 继承原始文本的字符间距 */
    line-height: inherit;
    /* 继承原始文本的行高 */
    pointer-events: none;
    /* 确保点击事件只作用于原始文本而非伪元素 */
    background: linear-gradient(to bottom, transparent 20%, #fff);
    /* 这里的rgba表示红、绿、蓝三原色及其对应的透明度 */
    -webkit-background-clip: text;
    /* Safari/Chrome */
    -moz-background-clip: text;
    /* Firefox */
  }
}

.totalRow {
  font-size: 15px;
  font-family: 'AlimamaFangYuanTiVF';
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: left;
  padding: 0 178px;

  .sp {
    color: #E7931C;
  }
}

.tabs {
  height: 165px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.48);
  margin-top: -8px;
  font-family: 'AlibabaPuHuiTi_3_75_SemiBold';

  .left {
    padding: 0 73px 0 0;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 165px;
  }

  .right {
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    .topBrandClass {
      display: flex;
    }

    .childBrandClass {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .child {
      white-space: nowrap;
    }

    .choosed {
      color: rgba(231, 147, 28, 1) !important;
      border-bottom: 2px solid #E7931C !important;
    }

    .textchoosed {
      color: rgba(231, 147, 28, 1) !important;
    }

    .tab {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 82px;
      line-height: 82px;
      text-align: center;
      font-size: 23px;
      font-size: 18px;
      color: #FFFFFF;
      border-bottom: 1px solid #979797;
    }

    .arrowdown {
      font-size: 14px;
      margin-left: 6px;
    }

    .child {
      width: 100%;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 2px;
    }
  }

  .child {
    color: #fff;
    margin-right: 30px;
  }
}

.productList {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 172px 50px 172px;

  .prdvo {
    min-width: 225px;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: #fff;
    overflow: hidden;
    border-radius: 0px 0px 6px 6px;
    margin-right: 19px;
    margin-bottom: 18px;

    .logo {
      min-width: 225px;
      min-height: 225px;
    }

    .itembottom {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 7px 12px;
      background: #ABABAB;
    }

    .name {
      word-wrap: break-word;
      width: 201px;
      font-size: 24px;
      text-align: left;
      height: 65px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box;
      -webkit-line-clamp: 3; // 超出多少行
      -webkit-box-orient: vertical;
      font-size: 15px;
      font-family: 'AlibabaPuHuiTi_3_55_Regular';
      color: #000000;
    }

    .desc {
      display: flex;
      align-items: center;
      flex: 1;

      .price {
        flex: 1;
        text-align: left;
        font-size: 22px;
        font-family: 'AlibabaPuHuiTi_3_85_Bold';
        color: #E93434;
        font-weight: bold;
      }

      .go {
        flex: 1;
        text-align: right;
        color: #fcecc0;
        font-size: 15px;
        font-family: 'AlibabaPuHuiTi_3_85_Bold';
      }

      .cha {
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }
}
</style>